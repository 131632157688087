// @flow

import * as React from 'react';
import bemify from 'bemify-js';

import ErrorBar from 'app/common/components/ErrorBar';
import { Anchor } from 'app/common/components/AccessibleElement';
import { isProductionEnv } from 'app/common/CommonUtils';
import 'app/common/components/CatchError.scss';

export type CatchErrorProps = {
  onError: (any, Object) => void,
  onTryAgain: () => void,
  children: React.Node,
};

// https://github.com/facebook/react/blob/b52a5624e95f77166ffa520476d68231640692f9/packages/react-reconciler/src/ReactFiberScheduler.js#L29-L31
type ErrorInfo = {
  componentStack: string,
};

type CatchErrorState = {
  error?: Error,
  errorInfo?: ErrorInfo,
  shouldReloadPage: boolean,
  isTryingAgain: boolean,
};

const bem = bemify('catch-error');

class CatchError extends React.Component<CatchErrorProps, CatchErrorState> {
  static defaultProps = {
    onError: () => {},
    onTryAgain: () => {},
  };

  state = {
    error: undefined,
    errorInfo: undefined,
    shouldReloadPage: false,
    isTryingAgain: false,
  };

  onTryAgain = () => {
    // Tell the outside world if it cares.
    this.props.onTryAgain();
    // Go back to rendering, hopefully it will work this time.
    this.setState({
      error: undefined,
      errorInfo: undefined,
      shouldReloadPage: false,
      isTryingAgain: true,
    });
  };

  componentDidCatch(error: ?Error, errorInfo: ErrorInfo) {
    // Guard against the off-chance that `undefined` is thrown.
    if (!error) {
      error = new Error('Unknown error');
    }
    // Tell the outside world if it cares.
    this.props.onError(error, { isTryingAgain: this.state.isTryingAgain });
    // And stop rendering our broken children.
    this.setState({
      error,
      errorInfo,
      // If we're in the middle of trying again, then let's change the message,
      // since something is more broken than we thought.
      shouldReloadPage: this.state.shouldReloadPage || this.state.isTryingAgain,
    });
  }

  componentDidUpdate() {
    // If we tried again, reset that flag.
    if (this.state.isTryingAgain) {
      this.setState({
        isTryingAgain: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    // If we re-rendered while holding onto an error, let's try again. Maybe the
    // app state has changed in such a way that our children won't be broken
    // this time around. Or maybe simply unmounting and remounting them will put
    // them in a better state.
    if (this.state.error) {
      this.setState({
        error: undefined,
        shouldReloadPage: false,
      });
    }
  }

  onRefresh() {
    window.location.reload();
  }

  render() {
    // If we caught an error, we don't want to render children, because those
    // children might just throw more errors.
    if (this.state.error !== undefined) {
      return (
        <ErrorBar
          className={bem()}
          clearErrors={this.onTryAgain}
          errors={[this.state.error]}
          kind=""
          shouldScrollIntoView={false}
        >
          {!isProductionEnv() && (
            <div>
              <details className={bem('__details')}>
                <summary className={bem('__summary')}>Component stack</summary>
                <pre className={bem('__stack')}>
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </pre>
              </details>
              <details className={bem('__details')}>
                <summary className={bem('__summary')}>Error stack</summary>
                <pre className={bem('__stack')}>
                  {this.state.error && this.state.error.stack}
                </pre>
              </details>
            </div>
          )}
          {this.state.shouldReloadPage ? (
            <p>
              Well, that’s not good. It seems to be stuck! Try{' '}
              <Anchor onClick={this.onRefresh}>reloading this page</Anchor> to
              try again.
            </p>
          ) : (
            <p>
              Uh oh, something is not quite right! This might be a temporary
              problem though, so{' '}
              <Anchor onClick={this.onTryAgain}>click here</Anchor> to try that
              again.
            </p>
          )}
          {/* Here, we do publish our email address, as the error may occur on the contact form */}
          <p>
            Please{' '}
            <Anchor href="https://developer.zapier.com/contact">
              contact support
            </Anchor>{' '}
            if this persists!
          </p>
        </ErrorBar>
      );
    }
    return this.props.children;
  }
}

export default CatchError;
