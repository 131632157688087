import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

type Args = {
  integrationId: string;
  version?: string;
};

const QUERY_KEY_PREFIX = 'query-audit-logs';
const getQueryKey = (args: Args) =>
  `${QUERY_KEY_PREFIX}-${args.integrationId}-${args.version}`;

export type AuditLog = {
  action: string;
  customuser: string;
  date: Date;
  id: string;
  message: string;
  version: string;
};

function formatResponseBody(body: any): AuditLog[] {
  return body.objects.map(auditLog => ({
    ...auditLog,
    date: new Date(auditLog.date),
  }));
}

function useQueryAuditLogs(
  args: Args,
  options?: UseQueryOptions<AuditLog[], Error>
) {
  const { integrationId, version } = args;
  const notify = useNotify();

  const queryKey = getQueryKey(args);
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(integrationId)}/history`,
    ZAPIER_APP_BASE_URL
  );
  const params = new URLSearchParams({ limit: '50' });
  if (version) {
    params.append('version', version);
  }
  url.search = params.toString();

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryAuditLogs;
export { getQueryKey, QUERY_KEY_PREFIX };
